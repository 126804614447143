<template>
  <div class="main-body" style="padding: 0">
    <div class="inner-wrapper">
      <div class="inner-main">
        <page-header :propButtons="page.buttons" :propConfig="page.header" :propModel="dataModel"></page-header>
        <div class="inner-main-body">
          <div class="row">
            <div class="col-md-6">
              <div class="card card-featured card-featured-primary mb-4">
                <div class="card-body">
                  <fieldset class="form-fieldset" style="margin-bottom: 0.2rem">
                    <legend>Producto</legend>
                    <div class="form-row">
                      <div class="form-group col-sm-3" style="margin-bottom: 0.5rem">
                        <label>Tipo equipo</label>
                        <select v-model="inventory.equipment_type_id" class="custom-select custom-select-sm">
                          <option :value="null">Seleccionar</option>
                          <option :value="item.id" v-for="(item, index) in equipment_types" :key="index">{{ item.name }}</option>
                        </select>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Marca</label>
                        <select v-model="inventory.brand_id" class="custom-select custom-select-sm">
                          <option :value="null">Seleccionar</option>
                          <option :value="item.id" v-for="(item, index) in brands" :key="index">{{ item.name }}</option>
                        </select>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Modelo</label>
                        <input v-model="inventory.model" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-md-3">
                        <label>Fec. adquisición</label>
                        <flat-pickr v-model="inventory.acquisition_date" class="form-control form-control-sm flatpickr-input"></flat-pickr>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="inventory.equipment_type_id == 2 || inventory.equipment_type_id == 3">
              <div class="card card-featured card-featured-primary mb-4">
                <div class="card-body">
                  <fieldset class="form-fieldset" style="margin-bottom: 0.2rem">
                    <legend>Detalle</legend>
                    <div class="form-row">
                      <div class="form-group col-sm-3">
                        <label>Número serie</label>
                        <input v-model="details.serie" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Procesador</label>
                        <input v-model="details.processor" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Disco</label>
                        <input v-model="details.disk" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>RAM</label>
                        <input v-model="details.ram" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
            <div class="col-md-6" v-if="inventory.equipment_type_id == 1">
              <div class="card card-featured card-featured-primary mb-4">
                <div class="card-body">
                  <fieldset class="form-fieldset" style="margin-bottom: 0.2rem">
                    <legend>Detalle</legend>
                    <div class="form-row">
                      <div class="form-group col-sm-3">
                        <label>Operador</label>
                        <select v-model="details.translator" class="custom-select custom-select-sm">
                          <option :value="null">Seleccionar</option>
                          <option :value="item" v-for="(item, index) in translators" :key="index">{{ item }}</option>
                        </select>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Número telefónico</label>
                        <input v-model="details.number" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>SIM CARD</label>
                        <input v-model="details.sim_card" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>IMEI</label>
                        <input v-model="details.imei" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Plan</label>
                        <input v-model="details.plan" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Costo plan</label>
                        <input v-model="details.plan_cost" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Costo equipo</label>
                        <input v-model="details.equipment_cost" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Contrato</label>
                        <input v-model="details.contract" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Tipo de contrato</label>
                        <select v-model="details.contract_type" class="custom-select custom-select-sm">
                          <option :value="null">Seleccionar</option>
                          <option :value="item" v-for="(item, index) in contract_types" :key="index">{{ item }}</option>
                        </select>
                      </div>
                      <div class="form-group col-sm-3">
                        <label>Próxima renovación</label>
                        <input v-model="details.next_renovation" type="text" class="form-control form-control-sm todo-mayuscula" />
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>

            <!--- BOTONES FOOTER -->
            <div class="col-md-12 text-center">
              <button @click="mtd_save" :disabled="cp_save" class="btn btn-primary btn-lg">Guardar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Son window-->
    <modal-1 class="bg-black-trans" :id="dataModal1.config.id" :propConfig="dataModal1.config" @action-close="mtd_closeModal1()">
      <component
        :is="dataModal1.slot.component"
        slot="content-modal"
        :propInfo="dataModal1.slot"
        @onload="mtd_componentOnload"
        @success="mtd_componentSuccess"
        @error="mtd_componentError"
        @action-close="mtd_closeModal1()"
      ></component>
    </modal-1>
    <!--End son window-->
  </div>
</template>
<script>
  import { mapActions, mapGetters } from "vuex";
  export default {
    components: {},
    data() {
      return {
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altInput: true,
          altFormat: "j M, Y",
          dateFormat: "Y-m-d",
        },
        equipment_types: [],
        brands: [],
        translators: ["MOVISTAR", "ENTEL", "BITEL", "CLARO"],
        contract_types: ["18 MESES", "12 MESES", "SOLO EQUIPO"],
        inventory: {
          equipment_type_id: null,
          brand_id: null,
          model: "",
          acquisition_date: null,
        },
        details: {
          //PC O LAPTOP
          serie: "",
          processor: "",
          disk: "",
          ram: "",
          //CELULAR
          translator: null,
          number: "",
          sim_card: "",
          imei: "",
          plan: "",
          plan_cost: "",
          equipment_cost: "",
          contract: "",
          contract_type: null,
          next_renovation: "",
        },
        dataModel: {
          name: "quotes-register", //AQUI EN INGLES SINGULAR
          alias: "Ingresar",
          icon: "ri-file-copy-2-line",
        },
        page: {
          header: {
            menu: ["Inventario", "Ingresar"],
          },
          buttons: {
            create: false,
            import: false,
            export: false,
          },
        },
        errorsBackend: {},
        //** MODAL1 */
        dataModal1: {
          slot: {
            component: null,
            data: {
              row: null,
              buttonSubmit: null,
              method: null,
            },
          },
          config: {
            title: null,
            id: "c-modal-1__new-receipt",
            idHash: "#c-modal-1__new-receipt",
            size: null,
            scroll: true,
          },
        },
      };
    },
    computed: {
      ...mapGetters(["authentication__user", "url_api"]),
      cp_save: function () {
        let state = true;
        if (
          this.inventory.equipment_type_id == 1 &&
          this.inventory.brand_id != null &&
          this.inventory.model != "" &&
          this.inventory.acquisition_date != null &&
          this.details.translator != null &&
          this.details.number != "" &&
          this.details.sim_card != "" &&
          this.details.imei != "" &&
          this.details.plan != "" &&
          this.details.plan_cost != "" &&
          this.details.equipment_cost != "" &&
          this.details.contract != "" &&
          this.details.contract_type != null &&
          this.details.next_renovation != ""
        ) {
          state = false;
        }
        if (
          (this.inventory.equipment_type_id == 2 || this.inventory.equipment_type_id == 3) &&
          this.inventory.brand_id != null &&
          this.inventory.mode != "" &&
          this.inventory.acquisition_date != null &&
          this.details.serie != "" &&
          this.details.processor != "" &&
          this.details.disk != "" &&
          this.details.ram != ""
        ) {
          state = false;
        }
        return state;
      },
    },
    created() {
      this.getData();
    },
    methods: {
      ...mapActions(["get", "post"]),
      /************* METODOS MODAL */
      mtd_componentOnload: function (response) {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("show");
      },
      mtd_componentSuccess: function (response) {
        this.mtd_closeModal1();
      },

      mtd_componentError: function () {
        //
      },
      mtd_closeModal1: function () {
        // eslint-disable-next-line no-undef
        $(this.dataModal1.config.idHash).modal("hide");
        this.dataModal1.slot = {
          component: null,
          data: {
            row: null,
            buttonSubmit: null,
            method: null,
          },
        };
      },
      /****************************** METODOS PROPIOS DEL COMPONENTE */
      getData: function () {
        this.get({
          url: this.url_api + "/inventory-requirements",
          authorization: true,
          token: localStorage.getItem("token"),
        })
          .then((response) => {
            this.equipment_types = response.equipment_types;
            this.brands = response.brands;
          })
          .catch((error) => {});
      },
      mtd_save: function () {
        this.post({
          url: this.url_api + "/products",
          params: {
            inventory: this.inventory,
            details: this.details,
          },
          authorization: true,
          token: localStorage.getItem("token"),
        })
          .then((response) => {
            this.$alert("Producto registrado correctamente.", "Mensaje", "success").then({});
            (this.inventory = {
              equipment_type_id: null,
              brand_id: null,
              model: "",
              acquisition_date: null,
            }),
              (this.details = {
                //PC O LAPTOP
                serie: "",
                processor: "",
                disk: "",
                ram: "",
                //CELULAR
                translator: null,
                number: "",
                sim_card: "",
                imei: "",
                plan: "",
                plan_cost: "",
                equipment_cost: "",
                contract: "",
                contract_type: null,
                next_renovation: "",
              }),
              (this.errorsBackend = {});
          })
          .catch((error) => {
            this.errorsBackend = error;
            this.$alert("Error al registrar el producto.", "Mensaje", "error").then({});
          });
      },
    },
  };
</script>
